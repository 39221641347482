import React from 'react'

import { OrganizationJsonLd, SiteLinksSearchBoxJsonLd, WebPageJsonLd } from 'next-seo'

type Props = { baseUrl: string }

export const HomepageSEO = ({ baseUrl }: Props) => {
	const urlWithSlash = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`

	return (
		<>
			<OrganizationJsonLd
				type="Corporation"
				name="BigCommerce"
				description="BigCommerce’s mission is to help merchants sell more at every stage of growth, from small startups, to mid-market businesses, to large enterprises. As a leading Open SaaS solution, BigCommerce empowers merchants to build, innovate and grow their businesses online. Simply put, we focus on being the best commerce platform so our customers can focus on what matters most: growing their businesses."
				logo="https://www-cdn.bigcommerce.com/assets/logos/bc-logo-dark.svg?mtime=20160329000432"
				address={{
					'@type': 'PostalAddress',
					addressLocality: 'Austin',
					addressRegion: 'TX',
					postalCode: '78726',
					streetAddress: '11305 4 Points Building 2, Suite 100'
				}}
				parentOrganization="BigCommerce"
				url="https://www.bigcommerce.com"
				sameAs={[
					'http://www.facebook.com/Bigcommerce',
					'https://x.com/Bigcommerce/',
					'http://www.linkedin.com/company/bigcommerce',
					'http://www.youtube.com/BigcommerceDotCom',
					'http://pinterest.com/bigcommerce/',
					'https://www.instagram.com/bigcommerce/'
				]}
				slogan="Ecommerce for a New Era"
				legalName="BigCommerce Holdings, INC."
				leiCode="549300MOVEFQ78DMPG58"
			/>

			<SiteLinksSearchBoxJsonLd
				name="BigCommerce"
				url={urlWithSlash}
				potentialActions={[
					{
						target: `${urlWithSlash}search?search`,
						queryInput: 'query'
					}
				]}
			/>

			<WebPageJsonLd id={urlWithSlash} url={urlWithSlash} name="BigCommerce" />
		</>
	)
}
