const pathNamesWithNoIndex = [
	'/start-your-free-30day-trial-1/',
	'/start-your-free-30day-trial/',
	'/start-your-free-trial-1/',
	'/start-your-free-trial-123/',
	'/start-your-free-trial-2/',
	'/start-your-free-trial-3/',
	'/start-your-free-trial-4/',
	'/starten-sie-ihre-testversion/',
	'/enterprise-trial/',
	'/start-your-mib-trial/',
	'/start-your-trial/',
	'/start-your-cbd-trial/',
	'/comienza-tu-prueba/',
	'/start-din-proveversion/',
	'/inizia-il-periodo-di-prova/',
	'/start-proveperioden/',
	'/starta-din-provperiod/',
	'/commencez-votre-periode-essai/',
	'/proefperiode-starten/'
]

export default pathNamesWithNoIndex
